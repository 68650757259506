var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { CircularProgress, useTheme, } from '@mui/material';
import { useMemo, useState, } from 'react';
import deepmerge from 'deepmerge';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import CopyLinkIconButton from '@shared/ui/misc/CopyLinkIconButton';
import { PortfolioCard, PortfolioContainer, PortfolioReferralLinkDisplay, } from '@entities/portfolio';
import QuestionMarkTooltip from '@shared/ui/tooltips/QuestionMarkTooltip';
import Tooltip from '@shared/ui/tooltips/Tooltip';
import { ReactComponent as DeleteIcon } from '@icons/wolfkit-light/trash-light.svg';
import { ReactComponent as DisabledIcon } from '@icons/wolfkit-light/lock-light.svg';
import { useGetExchangeAccountsQuery } from '@shared/api/exchange';
import { ContainerColumn, ContainerRow, alignToCenter } from '@components/styled';
import { BodySemiBold } from '@components/styled/Typography';
import Input from '@shared/ui/inputs/Input';
import Button from '@shared/ui/buttons/Button';
import SegmentButtons from '@shared/ui/buttons/SegmentButtons';
import Icon from '@shared/ui/icons/Icon';
import QuantityInput from '@shared/ui/inputs/QuantityInput';
import { useUpdatePortfolioMutation } from '@shared/api/portfolio';
import { IsDefined } from '@utils/js-ts';
import WalletSelect from '@shared/ui/inputs/WalletSelect';
import { getWalletsFromExchanges } from '@entities/wallet';
import { ClosePortfolioButton } from '@features/portfolio/portfolio-close';
import useAppNavigation from '@shared/lib/hooks/useAppNavigation';
import Link from '@shared/ui/navigation/Link';
import validationSchema from './validation';
const PortfolioContainerStyled = styled(PortfolioContainer)((props) => ({
    justifyContent: 'space-between',
    alignItems: 'start',
    gap: props.theme.spacing_sizes.l
}));
const Form = styled.form((props) => ({
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'start',
    justifyContent: 'start',
    gap: props.theme.spacing_sizes.l,
}));
const FieldsRow = styled(ContainerRow, { shouldForwardProp: propName => propName !== 'marginBottom' })((props) => ({
    width: 'auto',
    height: 'auto',
    justifyContent: 'space-between',
    marginBottom: props.marginBottom,
}));
const ActionsContainer = styled(FieldsRow)(() => ({
    gap: '10px',
}));
const ReferralContainer = styled(ContainerRow)(() => ({
    width: '100%',
    height: 'auto',
    gap: '10px',
}));
const FieldContainer = styled(ContainerColumn, { shouldForwardProp: propName => propName !== 'hasError' })(() => ({
    width: 'auto',
    height: 'auto',
}));
const InputWithValidationContainer = styled(FieldContainer, { shouldForwardProp: propName => propName !== 'hasError' })(props => ({
    marginBottom: props.hasError ?
        props.theme.spacing_sizes.xs : props.theme.spacing_sizes.xs * 4.25,
}));
const PriceFieldContainer = styled(FieldContainer)(() => ({
    maxWidth: 200,
}));
const FormColumn = styled(ContainerColumn, { shouldForwardProp: propName => propName !== 'gap' && propName !== 'justify' })((props) => ({
    maxWidth: 400,
    minHeight: 306,
    gap: props.gap || 'unset',
    justifyContent: props.justify,
}));
const FormColumnBlock = styled(ContainerColumn, { shouldForwardProp: propName => propName !== 'gap' })(props => ({
    gap: props.gap || 'unset',
}));
const FieldLabelContainer = styled(ContainerRow, { shouldForwardProp: propName => propName !== 'marginBottom' })((props) => ({
    width: 'auto',
    height: 'auto',
    alignItems: 'center',
    gap: props.theme.spacing_sizes.s,
    marginBottom: props.marginBottom,
}));
const FieldLabel = styled(BodySemiBold, { shouldForwardProp: propName => propName !== 'marginBottom' })((props) => ({
    marginBottom: props.marginBottom,
    letterSpacing: '-0.084px',
}));
const SegmentButtonsContainer = styled.div(() => ({
    minWidth: 160,
}));
const ReferralLinkContainer = styled.div();
const ProgressContainer = styled(alignToCenter(ContainerColumn))(() => ({
    height: '406px',
}));
const PortfolioReferralLinkDisplayStyled = styled(PortfolioReferralLinkDisplay)(() => ({
    maxWidth: '350px', // button width + gap
}));
const MonthlyFeeIncDecStep = 5;
const PortfolioEdit = ({ portfolio, onEditClose }) => {
    var _a, _b;
    const { t } = useTranslation();
    const theme = useTheme();
    const { routes } = useAppNavigation();
    const { data: { wallets, exchanges }, isFetching } = useGetExchangeAccountsQuery(undefined, {
        selectFromResult: (_a) => {
            var { data } = _a, rest = __rest(_a, ["data"]);
            return (Object.assign(Object.assign({}, rest), { data: {
                    exchanges: data || [],
                    wallets: getWalletsFromExchanges(data || []),
                } }));
        },
    });
    const { control, handleSubmit, getValues, watch, setValue, trigger, formState: { errors }, } = useForm({
        defaultValues: portfolio,
        resolver: yupResolver(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        validationSchema(t, wallets)),
    });
    const walletId = watch('walletId');
    const walletError = (_a = errors.walletId) === null || _a === void 0 ? void 0 : _a.message;
    const portfolioNameError = (_b = errors.portfolioName) === null || _b === void 0 ? void 0 : _b.message;
    const [updatePortfolio, updatePortfolioState] = useUpdatePortfolioMutation();
    const [visibility, setVisibility] = useState(portfolio.visibility);
    const [minimumBalance, setMinimumBalance] = useState(portfolio.minimum_balance_type);
    const parentExchange = useMemo(() => exchanges === null || exchanges === void 0 ? void 0 : exchanges.find(exchange => { var _a; return exchange.id.toString() === ((_a = portfolio.exchange) === null || _a === void 0 ? void 0 : _a.id.toString()); }), [exchanges, portfolio]);
    const preview = useMemo(() => deepmerge.all([
        portfolio, getValues(),
        // we dont need to display assessment review badge twice
        // (for PortfolioEdit container & PortfolioCard respectively)
        { assessmentPassed: true },
        { exchange: parentExchange }
    ]), [
        portfolio,
        parentExchange,
        getValues,
    ]);
    const onVisibilityChange = (value) => {
        setVisibility(value);
    };
    const onBalanceChange = (value) => {
        setMinimumBalance(value);
    };
    const cancel = () => {
        onEditClose();
    };
    const handleUpdate = (values) => __awaiter(void 0, void 0, void 0, function* () {
        const res = yield updatePortfolio({
            portfolioId: portfolio.id,
            portfolioName: values.portfolioName,
            subscriptionPrice: parseInt(`${values.subscriptionPrice}`, 10),
        });
        if (res.data) {
            onEditClose();
            updatePortfolioState.reset();
        }
    });
    const handleWalletSelect = (_ev, itemId) => {
        setValue('walletId', itemId);
        trigger('walletId');
    };
    if (isFetching) {
        return (_jsx(ProgressContainer, { children: _jsx(CircularProgress, {}) }));
    }
    if (!IsDefined(portfolio) || !IsDefined(wallets) || updatePortfolioState.isSuccess) {
        return null;
    }
    return (_jsxs(PortfolioContainerStyled, { variant: 'plain', portfolio: portfolio, viewType: 'edit', displayState: 'default', alwaysDisplayOverlay: true, children: [_jsx(PortfolioCard, { portfolio: preview, variant: 'plain', viewType: 'card', displayState: 'default', disabled: true, showActionsBtn: true }), _jsxs(Form, { onSubmit: handleSubmit(handleUpdate), children: [_jsxs(FormColumn, { children: [_jsx(InputWithValidationContainer, { hasError: Boolean(walletError), children: _jsx(WalletSelect, { label: t('portfolio.parent_exchange'), items: wallets.map(({ id, totalAssetsUsd, type, exchange, }) => ({
                                        key: exchange.id,
                                        items: [{
                                                walletType: type,
                                                id,
                                                amount: parseFloat(totalAssetsUsd),
                                                exchangeType: exchange.type,
                                                walletName: exchange.properties
                                                    .connectionName,
                                            }],
                                    })), value: walletId || '', onChange: handleWalletSelect, notification: walletError, notificationStatus: walletError ? 'error' : undefined, disabled: updatePortfolioState.isLoading }) }), _jsx(InputWithValidationContainer, { hasError: Boolean(portfolioNameError), children: _jsx(Controller, { name: 'portfolioName', control: control, render: ({ field, fieldState }) => {
                                        var _a, _b;
                                        return (_jsx(Input, { ref: field.ref, label: t('portfolio.portfolio_name'), value: field.value, onChange: field.onChange, onBlur: field.onChange, notification: (_a = fieldState.error) === null || _a === void 0 ? void 0 : _a.message, notificationStatus: ((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message) ? 'error' : undefined, disabled: updatePortfolioState.isLoading, maxCount: 30, filled: true }));
                                    } }) }), _jsxs(FieldsRow, { marginBottom: theme.spacing_sizes.xs, children: [_jsxs(FieldContainer, { children: [_jsxs(FieldLabelContainer, { marginBottom: theme.spacing_sizes.xs * 1.25, children: [_jsx(FieldLabel, { children: t('portfolio.monthly_subscription_fee') }), _jsx(QuestionMarkTooltip, { title: t('portfolio.minimum_balance'), placement: 'top-start', iconColor: theme.customColors.input.borderHover, arrow: true })] }), _jsx(PriceFieldContainer, { children: _jsx(Controller, { name: 'subscriptionPrice', control: control, render: ({ field }) => (_jsx(QuantityInput, { incDecStep: MonthlyFeeIncDecStep, value: field.value, onChange: field.onChange, disabled: updatePortfolioState.isLoading, roundToMultiplesOfStep: true, fullWidth: true, min: 0, max: 500 })) }) })] }), _jsxs(FieldContainer, { children: [_jsxs(FieldLabelContainer, { marginBottom: theme.spacing_sizes.xs * 1.25, children: [_jsx(FieldLabel, { children: t('portfolio.portfolio_visibility') }), _jsx(Tooltip, { title: t('placeholders.not_implemented', { ns: 'common' }), placement: 'top-start', arrow: true, children: _jsx(Icon, { size: 16, color: theme.customColors.input.borderHover, IconComponent: DisabledIcon }) })] }), _jsx(SegmentButtons, { segments: [
                                                    {
                                                        key: 'private',
                                                        displayValue: t('portfolio.visibility.private'),
                                                    },
                                                    {
                                                        key: 'public',
                                                        displayValue: t('portfolio.visibility.public'),
                                                    },
                                                ], currentSegmentKey: visibility, onSelect: onVisibilityChange })] })] }), _jsx(Link, { withArrow: true, children: t('portfolio.about_subscription_fee') })] }), _jsxs(FormColumn, { justify: 'space-between', children: [_jsxs(FormColumnBlock, { gap: theme.spacing_sizes.xs * 4, children: [_jsxs(ReferralLinkContainer, { children: [_jsx(FieldLabel, { marginBottom: theme.spacing_sizes.m, children: t('portfolio.share_link') }), _jsxs(ReferralContainer, { children: [_jsx(PortfolioReferralLinkDisplayStyled, { link: 'https://www.endrex.net/referral?r=xlfdadASwadxlfdadASwadxlfdadASwad' }), _jsx(CopyLinkIconButton, { link: 'https://www.endrex.net/referral?r=xlfdadASwadxlfdadASwadxlfdadASwad' })] })] }), _jsxs(FieldsRow, { children: [_jsxs(FieldContainer, { children: [_jsxs(FieldLabelContainer, { marginBottom: theme.spacing_sizes.xs * 1.25, children: [_jsx(FieldLabel, { children: t('portfolio.recommended_stop_loss') }), _jsx(Tooltip, { title: t('placeholders.not_implemented', { ns: 'common' }), placement: 'top-start', arrow: true, children: _jsx(Icon, { size: 16, color: theme.customColors.input.borderHover, IconComponent: DisabledIcon }) })] }), _jsx(QuantityInput, { value: 35, variant: 'percent', roundToMultiplesOfStep: true, incDecStep: MonthlyFeeIncDecStep, onChange: () => { }, fullWidth: true, disabled: true })] }), _jsxs(FieldContainer, { children: [_jsxs(FieldLabelContainer, { marginBottom: theme.spacing_sizes.xs * 1.25, children: [_jsx(FieldLabel, { children: t('portfolio.minimum_balance') }), _jsx(Tooltip, { title: t('placeholders.not_implemented', { ns: 'common' }), placement: 'top-start', arrow: true, children: _jsx(Icon, { size: 16, color: theme.customColors.input.borderHover, IconComponent: DisabledIcon }) })] }), _jsx(SegmentButtonsContainer, { children: _jsx(SegmentButtons, { segments: [
                                                                {
                                                                    key: '1_to_1',
                                                                    displayValue: '1 : 1',
                                                                },
                                                                {
                                                                    key: 'third_part',
                                                                    displayValue: '1 : 3',
                                                                },
                                                                {
                                                                    key: 'fifth_part',
                                                                    displayValue: '1 : 5',
                                                                },
                                                            ], currentSegmentKey: minimumBalance, onSelect: onBalanceChange, disabled: true }) })] })] })] }), _jsxs(ActionsContainer, { children: [_jsx(Button, { variant: 'filled', color: 'primary', type: 'submit', style: {
                                            flex: 1,
                                        }, isLoading: updatePortfolioState.isLoading, disabled: updatePortfolioState.isLoading, children: t('actions.save', { ns: 'common' }) }), _jsx(Button, { variant: 'tinted', color: 'primary', onClick: cancel, type: 'button', style: {
                                            flex: 1,
                                        }, disabled: updatePortfolioState.isLoading, children: t('actions.cancel', { ns: 'common' }) }), _jsx(ClosePortfolioButton, { variant: 'filled', color: 'error', startIcon: DeleteIcon, type: 'button', style: {
                                            flex: 1.5,
                                        }, selectedPortfolioId: portfolio.id, disabled: updatePortfolioState.isLoading, children: t('portfolio.actions.close') })] })] })] })] }));
};
export default PortfolioEdit;
